<template>
  <div class="content-layout-fixer" style="padding: 8px">
    <div>
      <!-- Kullanıcı kabul etmemişse -->
      <div class="text-layout" v-if="!isAgreed">
        <VueText class="text" color="#23303d" sizeLevel="6" weightLevel="3"
          >Kullanıcı Sözleşmesi</VueText
        >
        <div>
          <div v-html="disclaimerContent"></div>
          <div class="checkbox">
            <VueCheck id="checkRead" v-model="userAgreed"></VueCheck>
            <VueText color="#23303d" sizeLevel="5" weightLevel="3">
              Okudum Onaylıyorum
            </VueText>
          </div>
          <BrandButton @click="onAgreed">TAMAM</BrandButton>
        </div>
      </div>
      <div v-else class="text-layout">
        <!-- Kullanıcı kabul ETMİŞ -->
        <div v-if="videoURL">
          <BrandVimeoPlayer
            class="video-wrapper"
            :id="videoURL"
            ref="vimeoPlayer"
            :data="{ embedVideo: videoURL }"
          >
          </BrandVimeoPlayer>
        </div>
        <VueForm ref="form">
          <div class="point-box">
            <VueText color="grey-50" sizeLevel="5" weightLevel="3">
              Kullanılabilir
            </VueText>
            <div class="flex-item">
              <VueText style="padding-right: 4px;" color="#23303d" sizeLevel="6" weightLevel="4">
                {{ totalPoints }}
              </VueText>
              <VueText color="grey-30" sizeLevel="6" weightLevel="2">
                PUAN
              </VueText>
            </div>
          </div>
          <div>
            <VueHeadline
              level="4"
              weightLevel="3"
              color="grey-40"
              style="width: 100%; margin: 30px 0;"
              >Dönüştürülecek Puan Miktarını Giriniz
              <VueIcon
                :width="icons.refresh.width"
                :height="icons.refresh.height"
                :iconName="icons.refresh.name"
              />
            </VueHeadline>
            <div class="point-convert">
              <VueInput
                :label="`PMAktif Puanı`"
                :messageOnTop="true"
                type="number"
                name="convertingValue"
                id="convertingValue"
                v-model="convertingValue"
                validatorName="PMAktif Puan"
                :validationRules="`required|numeric|between:1,${totalPoints}`"
              >
              </VueInput>
              <VueIcon
                :width="icons.iconEqual.width"
                :height="icons.iconEqual.height"
                :iconName="icons.iconEqual.name"
                style="margin-top: 16px; width: 100%;"
              />
              <div class="cards-convert-quantity">
                <VueText color="grey-30" sizeLevel="5" weightLevel="2"
                  >Trendyol Cüzdan Bakiyesi</VueText
                >
                <div class="cards-convert-quantity-value">
                  <VueText sizeLevel="9" weightLevel="3">{{
                    pointFormatter(getConvertingQuantity)
                  }}</VueText>
                </div>
              </div>
            </div>
            <BrandButton type="submit" @click="onConvertValue"
              ><VueText weightLevel="3" color="white-100">DÖNÜŞTÜR</VueText></BrandButton
            >
          </div></VueForm
        >
        <BrandSuccessConvert
          :showComponent="showSuccess"
          :amount="pointFormatter(getConvertingQuantity)"
          @close="closeSuccessModal"
        ></BrandSuccessConvert>
      </div>
    </div>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import VueCheck from '@/components/shared/VueCheck/VueCheck.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline';
import VueIcon from '@/components/shared/VueIcon/VueIcon';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { numberFormatter } from '@/utils/publicHelper.js';
import { Trendyol } from '@/services/Api/index';
import { User } from '@/services/Api/index';
import BrandSuccessConvert from '@/components/brand/BrandSuccessConvert/BrandSuccessConvert';
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';

export default {
  name: 'Trendyol',
  components: {
    VueText,
    VueCheck,
    BrandButton,
    VueIcon,
    VueHeadline,
    VueInput,
    VueForm,
    BrandSuccessConvert,
    BrandVimeoPlayer,
  },
  beforeMount() {
    //istek
    this.discountRate = 0.21;
    Trendyol.getDisclaimerContent().then(res => {
      let {
        Data: { fixedContent },
      } = res.data;

      this.disclaimerContent = fixedContent;
    });
  },
  methods: {
    onAgreed() {
      if (this.userAgreed) {
        this.isAgreed = this.userAgreed;
        this.getPoints();
      }
    },
    getPoints() {
      Trendyol.getPointConversionRate().then(res => {
        if (res.data.Data) this.totalPoints = res.data.Data.point;
        this.discountRate = res.data.Data.conversionRate;
        return;
      });
    },
    async onConvertValue() {
      const isValid = await this.$refs.form.$refs.observer.validate();
      if (!isValid) return;
      await User.getUserMobileNo().then(res => {
        if (res.data.Data) this.phoneNumber = res.data.Data.mobileNo;
      });
      const modalConfig = {
        isModalOpened: true,
        modalText: `<div><b>${this.pointFormatter(this.getConvertingQuantity)}
        TL </b> değerindeki <b>
        ${this.pointFormatter(this.convertingValue)}
        </b> Puanınız 0${
          this.phoneNumber
        } nolu telefonunuza Trendyol Cüzdan kodu olarak gönderilecektir.</div><div style="margin-top: 24px;">
        <b>Onaylıyor musunuz?</b>
        </div>`,
        isDoubleButton: true,
        firstButtonText: 'EVET',
        secondButtonText: 'İPTAL',
        modalConfirmationText: '',
        alertType: 'error',
        firstButtonFn: () => {
          this.onAprrovedConvert();
        },
      };
      this.$store.dispatch('app/setModalConfig', modalConfig);
    },
    pointFormatter(value) {
      return numberFormatter(value);
    },
    onAprrovedConvert() {
      Trendyol.setConversion({
        pointToConvert: parseInt(this.convertingValue),
      })
        .then(res => {
          if (res.status === 200 && res.data.Data.success) {
            this.showSuccess = true;
            this.getPoints();
          } else {
            this.errorModal();
          }
        })
        .catch(err => {
          console.log(err);
          this.isAgreed = true;
          this.errorModal();
        });
    },
    closeSuccessModal() {
      this.showSuccess = false;
      this.$store.dispatch('app/setModalConfig', { isModalOpened: false });
      document.body.style.overflow = 'auto';
    },
    errorModal() {
      const errorModalConfig = {
        isModalOpened: true,
        modalText: 'İşleminiz gerçekleştirilememiştir.',
        modalConfirmationText: '',
        modalType: 'uploadAlert',
        alertType: 'error',
        isDoubleButton: true,
        firstButtonText: 'YENİDEN DENE',
        firstButtonFn: () => {
          this.onConvertValue();
        },
        secondButtonText: 'İPTAL',
        isBtnReverse: true,
      };
      this.$store.dispatch('app/setModalConfig', { isModalOpened: false });
      setTimeout(() => {
        this.$store.dispatch('app/setModalConfig', errorModalConfig);
      });
    },
  },

  computed: {
    icons() {
      return ICON_VARIABLES;
    },
    getConvertingQuantity() {
      if (this.convertingValue <= this.totalPoints) return this.convertingValue * this.discountRate;
      else return 0;
    },
  },
  data() {
    return {
      isAgreed: false,
      userAgreed: false,
      convertingValue: null,
      discountRate: 0,
      totalPoints: null,
      disclaimerContent: '',
      phoneNumber: null,
      showSuccess: false,
      videoURL: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.text-layout {
  margin: 0 auto;
  max-width: 663px;
  .text {
    margin: 32px 0;
  }
  .checkbox {
    display: flex;
    align-content: center;
    align-items: center;
    align-self: center;
    background: #f8f9f9;
    padding: 22px;
    margin: 20px 0;
  }
  .point-box {
    height: 62px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 0 40px;
    background: url('~@/assets/bg/bg-point.png') no-repeat center;
    .flex-item {
      display: flex;
      justify-content: center;
    }
  }
  .point-convert {
    margin: 30px 0;
    .cards-convert-quantity {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: flex-start;
      height: 74px;
      border: 1px solid #79838e;
      border-radius: 4px;
      padding: 10px 20px;
      flex: 1;
      display: flex;
      align-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      flex-direction: column;
    }
  }
}
</style>
