<template>
  <div v-if="showComponent" class="component-wrapper" @click.prevent="closeModal">
    <div
      id="contentWrapper"
      class="content-wrapper"
      :style="{
        background: `url('${require('@/assets/bg/bg-modal.png')}') top no-repeat, linear-gradient(315deg, rgb(210, 0, 81) 0%, rgb(255, 163, 0) 100%)`,
      }"
    >
      <a @click.stop="closeModal" class="close-button" id="closeButton">
        <VueIcon
          :iconName="getIcon.cross.name"
          iconColor="#ffffff"
          :width="getIcon.cross.width"
          :height="getIcon.cross.height"
        ></VueIcon>
      </a>
      <VueText weightLevel="10" sizeLevel="14" color="white-100" class="headline">{{
        `${amount} TL`
      }}</VueText>
      <VueText weightLevel="3" sizeLevel="11" color="white-100"
        >Trendyol Cüzdan Kodunuz başarılı bir şekilde eklendi</VueText
      >
      <img src="~@/assets/icons/check.svg" alt="check" />
      <BrandButton
        class="submit-button"
        :size="sizes.xxLarge"
        type="submit"
        @click.prevent="goToHome()"
        >Anasayfaya Dön</BrandButton
      >
    </div>
  </div>
</template>

<script>
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { ICON_VARIABLES } from '@/constants/component.constants';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesRoot from '@/router/routes/RoutesRoot';

export default {
  name: 'BrandSuccessConvert',
  components: {
    VueText,
    VueIcon,
    BrandButton,
  },
  props: {
    showComponent: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: String,
    },
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    getIcon() {
      return {
        cross: ICON_VARIABLES.cross,
      };
    },
  },
  methods: {
    closeModal(e) {
      let modalStatus = document.getElementById('contentWrapper').contains(e.target);
      if (!modalStatus || e.currentTarget.id === 'closeButton') {
        this.$emit('close', e);
      }
      this.goToHome();
    },
    goToHome() {
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.Feed.path}`);
    },
  },
};
</script>

<style scoped lang="scss">
.component-wrapper {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9998;
  top: 0;
  left: 0;
  backdrop-filter: blur(3px);
  background: rgba(0, 0, 0, 0.4);

  .content-wrapper {
    position: fixed;
    width: 490px;
    height: 75%;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    padding: 36px 45px;
    border-radius: palette-radius-level('2');
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
  .close-button {
    cursor: pointer;
    position: absolute;
    right: palette-space-level(20);
    top: palette-space-level(20);
  }
  .headline {
    font-size: 42px;
    font-weight: bold;
  }
  img {
    width: 200px;
    height: 200px;
  }
}
</style>
